import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {Col, Row} from 'react-bootstrap';

const MyProfile = styled.div`
  box-shadow: ${props => props.theme.shadow.hard};
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  
  ${MyProfile}:hover & {
    opacity: 1;
  }
`

const ProfileImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 0.75;
`

const ImgContainer = styled.div`
  position: relative;
`

const IconButtonWrapper = styled.a`
  transition: color 200ms;
  
  &:hover {
    color: lightgrey;
  }
`

function IconButton({icon, href}) {
  return (
    <IconButtonWrapper href={href}>
      <FontAwesomeIcon icon={icon} size={'2x'}/>
    </IconButtonWrapper>
  )
}

const TextWrapper = styled.div`
  padding: ${props => props.theme.padding.item.standard};
`

export default function Profile(props) {
  return (
    <MyProfile>
      <ImgContainer>
        <ProfileImage src={props.src} alt={props.alt}/>
        <Overlay>
          <Row style={{left: '54%', transform: 'translate(-50%, -50%)', position: 'absolute', bottom: '10%'}} >
            <Col xs={6} align={'center'}>
              <IconButton icon={faEnvelope} href={'mailto:' + props.email} />
            </Col>
            <Col xs={6} align={'center'}>
              <IconButton icon={faLinkedin} href={props.linkedinLink} />
            </Col>
          </Row>
        </Overlay>
      </ImgContainer>
      <TextWrapper>
        <h5 className={'text-center'}> {props.fullname}</h5>
        <h6 className={'text-center'}>{props.title}</h6>
        <p className={'text-center'}>{props.major}</p>
      </TextWrapper>
    </MyProfile>
  )
}
