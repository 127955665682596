import React from 'react';
import {Row, Col} from 'react-bootstrap';

import Layout from '../components/Layout';
import Profile from '../components/Profile';
import HoverCard from '../components/containers/HoverCard';
import CompanyLogo from '../components/CompanyLogo';
import SectionBlock from '../components/containers/SectionBlock';
import {ButtonLink} from '../components/Button';
import ItemContainer from '../components/containers/ItemContainer';
import useAboutPage from '../hooks/useAboutPage';

export default function About({location}) {

  const {
    aboutOurBranch,
    branchPhoto,
    leadershipMembers,
    consultantMembers,
    whereWeveGone
  } = useAboutPage();

  return (
    <Layout location={location}>

      <SectionBlock>
        <Row style={{alignItems: 'center'}}>
          <Col md={6}>
            <h3>About our branch</h3>
          </Col>
          <Col md={6}>
            <p>{aboutOurBranch.aboutOurBranch}</p>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <img
              alt={'Team'}
              src={branchPhoto.file.url}
              style={{backgroundColor: 'grey', width: '100%', borderRadius: '18px'}}
            />
          </Col>
        </Row>
      </SectionBlock>

      <SectionBlock>
        <h3>Leadership</h3>
        <Row className={'gy-4'}>
          {
            leadershipMembers.map((node, index, array) =>
              <Col lg={3} md={4} sm={6}>
                <Profile
                  fullname={node.name}
                  title={node.position}
                  email={node.email}
                  major={node.major}
                  interests={node.interests}
                  linkedinLink={node.linkedinLink}
                  src={'https:' + node.headshot.file.url}
                  alt={node.name}
                />
              </Col>
            )
          }
        </Row>
      </SectionBlock>

      <SectionBlock>
        <h3>Consultants</h3>
        <Row className={'gy-4'}>
          {
            consultantMembers.map((node, index, array) =>
              <Col lg={3} md={4} sm={6}>
                <Profile
                  fullname={node.name}
                  title={node.position}
                  email={node.email}
                  major={node.major}
                  interests={node.interests}
                  linkedinLink={node.linkedinLink}
                  src={'https:' + node.headshot.file.url}
                  alt={node.name}/>
              </Col>
            )
          }
        </Row>
      </SectionBlock>

      <SectionBlock>
        <h3>Where we've gone after our 180 DC careers</h3>
        <br/>
        <Row className={'gy-4'} style={{justifyContent: 'flex-start', alignItems: 'center'}}>
          {
            whereWeveGone.map((node, index, array) =>
              <Col xs={'6'} md={'4'} lg={'3'}>
                <HoverCard>
                  <ItemContainer>
                    <CompanyLogo name={node.name} link={node.link} logo={'https:' + node.logo.file.url}/>
                  </ItemContainer>
                </HoverCard>
              </Col>
            )
          }
        </Row>
      </SectionBlock>

      <SectionBlock>
        <div className={'text-center'}>
          <h1 className={'text-center'}>Looking to join our team?</h1>
          <br/><br/>
          <ButtonLink to={'/join/'} large>
            Join us
          </ButtonLink>
        </div>
      </SectionBlock>
    </Layout>
  );
}
