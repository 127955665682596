import {graphql, useStaticQuery} from 'gatsby';

const aboutPageQuery = graphql`
  query {
    allContentfulAboutPage {
      nodes {
        aboutOurBranch {
          aboutOurBranch
        }
        branchPhoto {
          file {
            url
          }
        }
        leadershipMembers {
          name
          email
          linkedinLink
          major
          position
          headshot {
            file {
              url
            }
          }
        }
        consultantMembers {
          name
          email
          linkedinLink
          major
          position
          headshot {
            file {
              url
            }
          }
        }
        whereWeveGone {
          name
          link
          logo {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default function useAboutPage() {
  const aboutPageNode = useStaticQuery(aboutPageQuery).allContentfulAboutPage.nodes[0];
  return aboutPageNode;
}